/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $searchBtn = $('.toggle-search-btn');

        $("#list-posts").mThumbnailScroller({
          axis:"x"
        });

        /* Reduce menu on scroll */
        $(window).on('scroll', function(){
          if( $(window).scrollTop() >= 10 ){
            $('header.main-header').addClass('reduced');
          }else{
            $('header.main-header').removeClass('reduced');
          }
        });

        /* Search box */
        $searchBtn.on('click', function(e){
          e.preventDefault();
          setTimeout(function(){$('.searchbox .search-field').focus()}, 500);

          $searchBtn.parents('.searchbox').toggleClass('open').promise().done(function(e){
            if( $(e).hasClass('open') ){
              $searchBtn.parents('.searchbox-desktop').width($(window).width() - $('#menu-menu-principal li:first-child').offset().left );
              $searchBtn.parents('.searchbox-desktop form').css('visibility','visible');
            }else{
              $searchBtn.parents('.searchbox-desktop form').delay(400).css('visibility','hidden').css('width','0');
              setTimeout(function(){
                $searchBtn.parents('.searchbox-desktop').css('width','0');
              },200);
            }
          });
        });

        /* Activate menu on scroll & display backtop button */
        $(window).on('scroll',function(e){
          if( $(window).width() >= 1020 ){
            if( $(window).scrollTop() > 1200 ){
              $('.backtop').css({'opacity':'1','display':'block'});
            }else{
              $('.backtop').css({'opacity':'0','display':'none'});
            }
          }else{
            if( $(window).scrollTop() > 1600 ){
              $('.backtop').css({'opacity':'1','display':'block'});
            }else{
              $('.backtop').css({'opacity':'0','display':'none'});
            }
          }
        });
        /* Scroll to */
        $('.scrollto').on('click', function(e){
          e.preventDefault();
          var id = $(this).attr('href');
          if( $(this).attr('data-scrollback') ){
            $('html, body').animate({scrollTop: $(id).offset().top-$(this).attr('data-scrollback')}, 500);
          }else{
            $('html, body').animate({scrollTop: $(id).offset().top}, 500);
          }
        });

        /* Mobile menu */
        if( $(window).width() <= 992 ) {
          //Force sub-menu to close when menu is open
          $('.btn-menu-mobile').on('click', function(){
            if( $(this).attr('aria-expanded') == 'false' ){
              $('.nav-primary .sub-menu').removeClass('active');
            }
          });

          $('.nav-mobile ul.nav > li.menu-item-has-children').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active-link');
            if( $(this).hasClass('active-link') ){
              //$('.nav-primary .sub-menu').removeClass('active');
              $(this).find('.sub-menu').toggleClass('active');
            }else{
              $(this).find('.sub-menu').toggleClass('active');
            }
          });
          $('.sub-menu li').on('click', function(e){
            e.stopPropagation();
          });
        }

        $('.backtop').on('click', function(e){
          e.preventDefault();
          $('html, body').animate({scrollTop: 0}, 500);
        });

        /* Mobile friendly carousels */
        $('.carousel').bcSwipe({ threshold: 50 });

        $('.carousel').on('slide.bs.carousel', function (e) {
          var nextH = $(e.relatedTarget).height();
          $(this).find('.carousel-item.active').parent().animate({
            height: nextH
          }, 500);
        });

        /* Scrolling animation triggering */
        /* Mise en place effet au scroll */
        $.fn.visible = function(partial) {
          var $t            = $(this),
            $w            = $(window),
            viewTop       = $w.scrollTop(),
            viewBottom    = viewTop + $w.height(),
            _top          = $t.offset().top,
            _bottom       = _top + $t.height(),
            compareTop    = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

          return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
        };

        //Fix carousel heights
        var maxHeight = 0;

        //$('.carousel .carousel-item').each(function(){
        //  var thisH = $(this).height();
        //  if (thisH > maxHeight) {
        //    maxHeight = thisH;
        //  }
        //  $(this).animate({"height":maxHeight});
        //});


        $(".module").each(function(i, el) {
          var el = $(el);
          if (el.visible(true)) {
            el.removeClass("module");
          }
        });

        $(".module-start").each(function(i, el) {
          var el = $(el);
          if (el.visible(true)) {
            el.addClass("come-in");
          }
        });

        $(window).scroll(function(event) {

          $(".module").each(function(i, el) {
            var el = $(el);
            if (el.visible(true)) {
              el.addClass("come-in");
            }
          });

          $(".module-slow").each(function(i, el) {
            var el = $(el);
            if (el.visible(true) ) {
              el.addClass("fadein");
            }
          });

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(window).on('load', function(){
          $('#carouselNews').carousel({
            interval: 5000,
            keyboard: true
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single document
    'single_document': {
      init: function() {
        /* Prev/Next custom tab links */
        //$('.tab-link').on('click', function(e){
        //  e.preventDefault();
        //  var current = $('.nav-tabs a.active');
        //  if( $(this).hasClass('prev-tab') ){
        //    if( current.parent().prev().length == 0 ){
        //      current.parent().next().find('a').trigger('click');
        //    }else{
        //      current.parent().prev().find('a').trigger('click');
        //    }
        //  }else{
        //    if( current.parent().next().length == 0 ){
        //      current.parent().prev().find('a').trigger('click');
        //    }else{
        //      current.parent().next().find('a').trigger('click');
        //    }
        //  }
        //});

      },
      finalize: function() {}
    },
    'single_album_photo': {
      init: function() {

        $("#list-posts2").mThumbnailScroller({
          axis:"x"
        });

        $('ul.mTSContainer li.mTSThumbContainer').on('click', function(e){
          console.log('okokk');
          $('ul.mTSContainer li.mTSThumbContainer').attr('data-active',false);
          $(this).attr('data-active',true);
        });

      },
      finalize: function() {}
    },
    'page_template_default': {
      init: function() {
      }
    },
    'page_template_template_carte': {
      init: function() {
        /* Display modal on mobile only */
        if( $(window).width() < 576 ){
          $('#modalInfo').modal();
        }
        /* mapCarousel init */
        $('#mapCarousel').carousel({
          ride: false,
          interval: false,
          keyboard: true
        });
        $('#mapCarousel').carousel(1);

        /* Legende filters */
        $('.carte-legende .legend-item').on('click', function(e){
          $(this).toggleClass('active');
          e.stopPropagation();
          $('.carte-container .map-pin[data-type="'+$(this).attr('data-type')+'"]').toggle();
        });

        /* Legend + button */
        $('.carte-legende .legend-item a').on('click', function(e){
          e.preventDefault();
          e.stopPropagation();
          var index = $('#mapCarousel').find('.carousel-item[data-id="'+$(this).parents('.legend-item').attr('data-type')+'"]').index();
          $('#mapCarousel').carousel(index);
          $('html,body').animate({scrollTop: $("#mapCarousel").offset().top-60}, 'slow');
        });

        /* Map click on bulles */
        $('.carte-container .map-pin[data-type="zones_humides"]').on('click', function(){
          var index = $('#mapCarousel').find('.carousel-item[data-id="'+$(this).attr('data-id')+'"]').index();
          $('#mapCarousel').carousel(index);
          $('html,body').animate({scrollTop: $("#mapCarousel").offset().top-60}, 'slow');
        });

        /* Map click on travaux */
        $('.carte-container .map-pin[data-type="travaux_berges"]').on('click', function(){
          var index = $('#mapCarousel').find('.carousel-item[data-id="'+$(this).attr('data-id')+'"]').index();
          $('#mapCarousel').carousel(index);
          $('html,body').animate({scrollTop: $("#mapCarousel").offset().top-60}, 'slow');
        });

        /* Map click on poissons */
        $('.carte-container .map-pin[data-type="travaux_eco"]').on('click', function(){
          var index = $('#mapCarousel').find('.carousel-item[data-id="'+$(this).attr('data-id')+'"]').index();
          $('#mapCarousel').carousel(index);
          $('html,body').animate({scrollTop: $("#mapCarousel").offset().top-60}, 'slow');
        });

        /* Zoom map buttons */
        var zoomScale = 1;
        $('.zoom').on('click', function(e){
          e.preventDefault();

          if( $(this).hasClass('zoom-plus') ){
            if( zoomScale < 2 ){
              $('.scalable').css('transform','translateX(0) translateY(0) scale('+(zoomScale+=0.2)+')').css({left:0,top:0});
            }
          }else{
            if( zoomScale > 1 ){
              $('.scalable').css('transform','translateX(0) translateY(0) scale('+(zoomScale-=0.2)+')').css({left:0,top:0});
            }
          }

          if( $(window).width() < 960 ){
            $('#map').addClass('scaled');
          }
          if( zoomScale > 1 ){
            $('#map').addClass('scaled');
          }else if( zoomScale == 1 ){
            $('#map').removeClass('scaled');
          }
        });

        /* Hovers on map */
        $('.carte-container *[data-type="hoverable"]').hover(
          function() {
            $('.carte-container .desc-pin[data-type="'+$(this).attr('class')+'"]').show();
          }, function() {
            $('.carte-container .desc-pin[data-type="'+$(this).attr('class')+'"]').hide();
          }
        );

        /* Drag&Drop */
        $('#map').on('mousedown', function (e) {

          if( zoomScale > 1 ){
            $(this).addClass('draging');

            var oTop = e.pageY - $('.draging').offset().top;
            var oLeft = e.pageX - $('.draging').offset().left;

            $(this).parents().on('mousemove', function (e) {
              $('.draging').offset({
                top: e.pageY - oTop,
                left: e.pageX - oLeft
              }).on('mouseup', function () {
                $(this).removeClass('draging');
              });
            });
          }
          return false;
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
